import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string
}

ConfirmDialog.defaultProps = {
  title: 'Confirm',
  text: 'Are you certain?',
  cancelText: 'Cancel',
  confirmText: 'OK'
}

export default function ConfirmDialog (props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  function handleClose () {
    props.setOpen(false)
  }

  function handleConfirm () {
    handleClose()
    props.action()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'xs'}
      fullWidth
      open={props.open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          {props.cancelText}
        </Button>
        <Button onClick={handleConfirm} color='primary' autoFocus>
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

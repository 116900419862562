import _ from 'lodash/fp'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

import localStore from 'localforage'

import { SESSION_KEY } from './keys'

const cache = new InMemoryCache()
const httpLink = new HttpLink({
  uri: 'https://magic-api.ratio.dk/'
})

const authLink = setContext(async (request, ctx) => {
  const session = await localStore.getItem(SESSION_KEY)
  if (session) {
    return _.setWith(Object, 'headers.session', session, ctx)
  } else {
    return ctx
  }
})

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink)
})

export default client

import React from 'react'
import _ from 'lodash/fp'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import PrimaryListHandleDialog from './PrimaryListHandleDialog'

import { GET_LISTS, VERIFY_SESSION } from 'common/queries'
import { useQuery } from '@apollo/react-hooks'
import LoadingIndicator from 'components/LoadingIndicator'
import Summary from './Summary'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

CollectionSummary.propTypes = {
  className: PropTypes.string
}

export default function CollectionSummary (props) {
  const { className } = props

  const [dialogOpen, setDialogOpen] = React.useState(false)

  const classes = useStyles()

  const { data: lists, loading: listsLoading } = useQuery(GET_LISTS)
  const { data: profileData, loading: profileLoading } = useQuery(VERIFY_SESSION)

  const profile = _.get('auth.verifySession.profile', profileData)
  const primaryListHandle = _.getOr('', 'primaryListHandle', profile)

  const primaryList = _.flow(
    _.get('lists.getLists'),
    _.find({ handle: primaryListHandle })
  )(lists)

  const loading = (listsLoading || profileLoading) && !lists && !profileData

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            disabled={loading}
            color='primary'
            size='small'
            variant='outlined'
            onClick={() => { setDialogOpen(true) }}
          >
            Change collection list
          </Button>
        }
        title={'Collection Summary' + (primaryList ? `: ${primaryList.name}` : '')}
      />
      <Divider />
      <CardContent className={classes.content}>
        {loading ? <LoadingIndicator /> : <Summary list={primaryList} />}
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          disabled={loading}
          color='primary'
          size='small'
          variant='text'
        >
          View list <ArrowRightIcon />
        </Button>
      </CardActions>
      <PrimaryListHandleDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </Card>
  )
}

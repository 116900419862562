import React from 'react'
import _ from 'lodash/fp'
import Select from 'react-select'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const selectStyles = {
  input: base => ({
    ...base,
    color: '#000',
    '& input': {
      font: 'inherit'
    }
  }),
  option: base => ({
    ...base,
    color: '#000'
  })
}

const FIND_CARDS = gql`
query FindCardsQuery($cardName: String!) {
  cards {
    findCards(
      textFilters:{field:name value: $cardName}
    ){
      name
      set
      tix
      mtgoId
    }
  }
}
`

const optionsFromData = _.flow(
  _.get('cards.findCards'),
  _.map(c => ({
    value: c.mtgoId,
    set: c.set,
    label: `${_.toUpper(c.set)} ${c.name} - ${c.tix} tix`
  }))
)

export default function SearchCard ({ onSelect = _.identity, ...rest }) {
  const [value, setValue] = React.useState('')
  const { loading, data } = useQuery(FIND_CARDS, { variables: { cardName: value } })
  const handleOnChange = React.useCallback((response) => {
    if (response) {
      onSelect({ mtgoId: response.value, set: response.set })
    } else {
      console.warn('Somehow did not get a response from card searching selection')
    }
  }, [onSelect])
  return (
    <Select
      isSearchable
      isClearable
      loading={loading}
      inputId='react-select-single'
      styles={selectStyles}
      TextFieldProps={{
        label: 'Card',
        InputLabelProps: {
          htmlFor: 'react-select-single',
          shrink: true
        }
      }}
      onInputChange={value => { setValue(value) }}
      onChange={handleOnChange}
      placeholder='Search for a magic card'
      value={value}
      options={optionsFromData(data)}
      {...rest}
    />
  )
}

import gql from 'graphql-tag'

export const GET_LISTS = gql`
  query GetListsQuery {
    lists {
      getLists {
        handle
        name
        createdAt
        uniqueCards
        totalCards
        totalTix
      }
    }
  }
`

export const GET_LIST = gql`
  query GetListQuery($listHandle: String!) {
    lists {
      getList(handle: $listHandle) {
        name
        handle
        cards {
          name
          set
          mtgoId
          createdAt
          updatedAt
          tix
          delta
          deltaWeek
          deltaMonth
          deltaQuarter
          quantity
        }
      }
    }
  }
`

export const GET_SETS = gql`
  query GetSetsQuery {
    cards {
      getSets {
        code
        mtgoCode
        releasedAt
        year
      }
    }
  }
`

export const VERIFY_SESSION = gql`
  query VerifySession {
    auth {
      verifySession {
        profile {
          handle
          name
          email
          primaryListHandle
        }
      }
    }
  }
`

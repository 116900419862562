import React from 'react'
import _ from 'lodash/fp'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import Checkbox from '@material-ui/core/Checkbox'

import TableSortLabel from '@material-ui/core/TableSortLabel'
import { AutoSizer, Table, Column, defaultTableHeaderRenderer, WindowScroller } from 'react-virtualized'
import 'react-virtualized/styles.css'

import SelectedCardsBar from './SelectedCardsBar'
import { LinkRouter } from 'components/LinkRouter'

const useStyles = makeStyles(theme => ({
  root: {},
  cardTableContainer: {
    padding: 0
  },
  close: {
    padding: theme.spacing(0.5)
  },
  cell: {
    borderTop: 'solid rgba(224, 224, 224, 1) 1px',
    '&:focus': {
      outline: 'none'
    }
  }
}))

const headerRendererBase = ({sort, asc}) => props => {
  return (
    <TableSortLabel
      active={sort === props.dataKey}
      direction={asc ? 'asc' : 'desc'}
    >
      {defaultTableHeaderRenderer(props)}
    </TableSortLabel>
  )
}

const defaultColumnProps = {
  style: {padding: 14, margin: 0},
  width: 0,
  flexGrow: 1
}

CardTable.propTypes = {
  cards: PropTypes.array.isRequired,
  selectedCards: PropTypes.arrayOf(PropTypes.number),
  setSelectedCards: PropTypes.func,
  upsertCards: PropTypes.func.isRequired
}

CardTable.defaultProps = {
  cards: []
}

export default function CardTable ({
  cards,
  selectedCards,
  setSelectedCards,
  upsertCards
}) {
  const classes = useStyles()

  const [sorting, setSorting] = React.useState({ sort: 'tix', asc: false })
  const headerRenderer = headerRendererBase(sorting)
  const commonColumnProps = _.set('headerRenderer', headerRenderer, defaultColumnProps)

  // small helper to initialise a good card count in the cards bar
  const [selectedAmount, setSelectedAmount] = React.useState(0)

  const sortedCards = React.useMemo(() => _.orderBy(
    [sorting.sort, 'name'],
    [
      sorting.asc ? 'asc' : 'desc',
      _.isEqual('name', sorting.sort)
        ? sorting.asc ? 'asc' : 'desc'
        : 'asc'
    ],
    cards
  ), [cards, sorting])

  const handleHeaderClick = React.useCallback(row => {
    if (row.dataKey === 'mtgoId') {
      return
    }
    if (sorting.sort === row.dataKey) {
      setSorting(_.update('asc', direction => !direction))
    } else {
      setSorting(_.set('sort', row.dataKey))
    }
  }, [sorting, setSorting])

  function handleToggleSelectAll () {
    if (_.isEmpty(selectedCards)) {
      setSelectedCards(_.map('mtgoId', cards))
    } else {
      setSelectedCards([])
    }
    setSelectedAmount(0)
  }

  const rowClassName = React.useCallback(data => {
    return ~(data.index) && classes.cell
  }, [classes])

  function handleCheckboxChange (event) {
    const mtgoId = parseInt(event.target.value)
    setSelectedCards(selectedCards => {
      const result = _.includes(mtgoId, selectedCards)
        ? _.without([mtgoId], selectedCards)
        : _.concat(selectedCards, [mtgoId])
      if (_.size(result) === 1) {
        const amount = parseInt(event.target.name)
        setSelectedAmount(_.isNaN(amount) ? 0 : amount)
      }
      return result
    })
  }

  return (
    <Card
      className={clsx(classes.root)}
    >
      <div className={classes.cardTableContainer}>
        <WindowScroller>
          {({height, onChildScroll, isScrolling, scrollTop}) => (
            <AutoSizer disableHeight>
              {({width}) => (
                <Table
                  autoHeight
                  scrollTop={scrollTop}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  width={width}
                  height={height}
                  rowGetter={({index}) => _.nth(index, sortedCards)}
                  rowCount={_.size(sortedCards)}
                  rowClassName={rowClassName}
                  headerClassName={classes.cell}
                  onHeaderClick={handleHeaderClick}
                  rowHeight={25}
                  headerHeight={50}
                  headerStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 0,
                    padding: 14,
                    fontSize: '0.875rem',
                    textTransform: 'none',
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontWeight: 'normal'
                  }}
                >
                  <Column {...defaultColumnProps}
                    flexGrow={1}
                    headerRenderer={p => (
                      <Checkbox
                        checked={_.size(selectedCards) === _.size(cards) && !_.isEmpty(cards)}
                        onChange={handleToggleSelectAll}
                        value={'checkbox-header'}
                      />
                    )}
                    cellRenderer={p => (
                      <Checkbox
                        checked={(_.includes(p.rowData.mtgoId, selectedCards))}
                        value={p.rowData.mtgoId}
                        name={`${p.rowData.quantity}` /* this is a hack to help initialise quantity */}
                        onChange={handleCheckboxChange}
                      />
                    )}
                    dataKey={'mtgoId'}
                  />
                  <Column {...commonColumnProps} label='#' dataKey='quantity' />
                  <Column {...commonColumnProps} flexGrow={4} label='Name' dataKey='name'
                    cellRenderer={(p) => (
                      <LinkRouter
                        component='button'
                        variant='body1'
                        name={p.rowData.mtgoId}
                        to={`/cards/${p.rowData.set}/${p.rowData.mtgoId}`}
                      >
                        {p.cellData}
                      </LinkRouter>
                    )}
                  />
                  <Column {...commonColumnProps} flexGrow={2} label='Set' dataKey='set' />
                  <Column {...commonColumnProps} flexGrow={2} label='Tix' dataKey='tix' />
                  <Column {...commonColumnProps} flexGrow={2} label='Δ' dataKey='delta' />
                  <Column {...commonColumnProps} flexGrow={2} label='Δweek' dataKey='deltaWeek' />
                  <Column {...commonColumnProps} flexGrow={2} label='Δmonth' dataKey='deltaMonth' />
                  <Column {...commonColumnProps} flexGrow={2} label='Δquarter' dataKey='deltaQuarter' />
                </Table>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </div>
      <SelectedCardsBar
        selectedAmount={selectedAmount}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
        upsertCards={upsertCards}
      />
    </Card>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'

import SearchCard from 'components/SearchCard'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  }
}))

ListToolbar.propTypes = {
  className: PropTypes.string,
  setAddDialogMtgoId: PropTypes.func.isRequired,
  handleImportCards: PropTypes.func.isRequired
}

export default function ListToolbar (props) {
  const classes = useStyles()

  function handleAddCard ({ mtgoId }) {
    props.setAddDialogMtgoId(mtgoId)
  }

  return (
    <div
      className={clsx(classes.root, props.className)}
    >
      <div className={classes.row}>
        <div style={{ minWidth: '300px' }}>
          <SearchCard
            onSelect={handleAddCard}
            placeholder='Search to add cards'
          />
        </div>
        <span className={classes.spacer} />
        <React.Fragment>
          <Button
            onClick={props.handleImportCards}
            color='secondary'
            className={classes.marginLeft}
            variant='outlined'
            size='large'
          >
              Import
          </Button>
        </React.Fragment>
      </div>
    </div>
  )
}

export default {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  handle: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: '[a-z0-9\\-]+',
      flags: 'g',
      message: 'can only contain a-z and 0-9'
    },
    length: {
      maximum: 128
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 128
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
}

import React from 'react'
import _ from 'lodash/fp'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import CardsList from 'components/CardsList'
import CollectionSummary from './components/CollectionSummary'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_STATS_CARDS = gql`
  query GetStatsCards ($field: SearchNumberField!, $operator: OrderByOperator) {
    cards {
      findCards (
        orderBy: [{ field: $field, operator: $operator }],
        limit: 10,
        offset: 0
      ) {
        mtgoId
        name
        set
        tix
        delta
        deltaWeek
        deltaQuarter
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const Dashboard = () => {
  const classes = useStyles()

  const { data: mostValuable } = useQuery(GET_STATS_CARDS, { variables: { field: 'tix', operator: 'DESC' } })
  const { data: weeklyWinners } = useQuery(GET_STATS_CARDS, { variables: { field: 'delta_week', operator: 'DESC' } })
  const { data: weeklyLosers } = useQuery(GET_STATS_CARDS, { variables: { field: 'delta_week', operator: 'ASC' } })
  const { data: quarterlyLosers } = useQuery(GET_STATS_CARDS, { variables: { field: 'delta_quarter', operator: 'ASC' } })

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CardsList
            title='Most valuable cards'
            cards={_.get('cards.findCards', mostValuable)}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CardsList
            title='Weekly winners'
            cards={_.get('cards.findCards', weeklyWinners)}
            deltaKey='deltaWeek'
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CardsList
            title='Weekly losers'
            cards={_.get('cards.findCards', weeklyLosers)}
            deltaKey='deltaWeek'
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CardsList
            title='Quarterly losers'
            cards={_.get('cards.findCards', quarterlyLosers)}
            deltaKey='deltaQuarter'
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <CollectionSummary />
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard

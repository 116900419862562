import React from 'react'
import _ from 'lodash/fp'
import gql from 'graphql-tag'
import makeStyles from '@material-ui/styles/makeStyles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Link from '@material-ui/core/Link'
import { useLazyQuery } from '@apollo/react-hooks'
import { GreenButton } from 'components/Buttons'

const testInput = `

4 Ash Barrens
4 Augur of Bolas
3 [mmq] Brainstorm
2 Deep Analysis
4 Delver of Secrets
2 Dispel
2 Fire/Ice
2 Leap
2 Lightning Bolt
3 Mutagenic Growth
2 Ponder
4 Preordain
4 Seeker of the Way
2 Shadow Rift
2 Sky Theater Strix
9 Snow-Covered Island
1 Snow-Covered Mountain
1 Snow-Covered Plains
3 Terramorphic Expanse
2 Tethmos High Priest
2 Tranquil Cove
`

const GET_PRINT_CARDS = gql`
query getPrintCards($cards:[PrintCardFilter]) {
  cards {
    getPrintCards(cards: $cards) {
      name
      set
      url
      collectorNumber
      count
    }
  }
}`

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))

export default function Print (props) {
  const classes = useStyles()

  const [getPrintCards, { data: printData, loading: printLoading }] = useLazyQuery(GET_PRINT_CARDS)

  const [input, setInput] = React.useState(testInput)
  const handleInput = React.useCallback(e => {
    setInput(e.target.value)
  }, [setInput])
  const handleGenerate = React.useCallback(_event => {
    const regex = /^(\d+) (\[(.+)\] )?(.*)$/gm
    let i = 0
    let r
    let l = []
    while ((r = regex.exec(input))) {
      if (i++ > 999) {
        console.warn('oh fuck')
        break
      }
      l.push({
        name: r[4],
        count: parseInt(r[1]),
        set: r[3]
      })
    }
    getPrintCards({ variables: { cards: l } })
  }, [input, getPrintCards])

  const CardLinks = React.useMemo(() => {
    const cards = _.get('cards.getPrintCards', printData)
    if (!cards) {
      return false
    }
    return (
      <ul>
        {_.map(c => (
          <li key={`${c.set}${c.collectorNumber}`}>
            <Link color='inherit' href={c.url}>
              {`${c.set}-${c.collectorNumber}`}
            </Link>
          </li>
        ), cards)}
      </ul>
    )
  }, [printData])

  const CardsPre = React.useMemo(() => {
    const cards = _.get('cards.getPrintCards', printData)
    if (!cards) {
      return false
    }
    return (
      <pre>
        {_.flow(
          _.map(c => _.repeat(c.count, `${c.set}-${c.collectorNumber}\n`)),
          _.join('')
        )(cards)}
      </pre>
    )
  }, [printData])

  return (
    <div className={classes.root}>
      <TextareaAutosize
        placeholder={`3 naturalize\n4 [me1] force of will`}
        rows={10}
        cols={40}
        rowsMax={25}
        value={input}
        onChange={handleInput}
      />
      <GreenButton onClick={handleGenerate}>HELLO</GreenButton>
      {printLoading
        ? <p>..</p>
        : Boolean(printData) && (
          <div>
            <h4>Links</h4>
            {CardLinks}
            <h4>Pre</h4>
            {CardsPre}
          </div>
        )}
    </div>
  )
}

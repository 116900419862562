import React from 'react'
import _ from 'lodash/fp'
import LinkRouter from 'components/LinkRouter'
import { Typography, Grid } from '@material-ui/core'

const makeYearSetsPairs = _.flow(_.groupBy('year'), _.toPairs)

export default function SetsList ({ sets, selectedSet }) {
  const yearSetsPairs = React.useMemo(() => makeYearSetsPairs(sets), [sets])
  return (
    <Grid container spacing={2}>
      {_.map(([year, sets]) => (
        <Grid item key={year}>
          <Typography variant='h4'>{year === '0' ? 'Special Sets' : year}</Typography>
          {_.map(s => (
            <div key={s.code} style={s.code === selectedSet ? { fontWeight: 'bold' } : {}}>
              <LinkRouter to={`/cards/${s.code}`}>{_.toUpper(s.code)}</LinkRouter>
            </div>
          ), sets)}
        </Grid>
      ), yearSetsPairs)}
    </Grid>
  )
}

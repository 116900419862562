import React from 'react'
import _ from 'lodash/fp'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import UploadButton from 'components/UploadButton'

import { GET_LIST } from 'common/queries'

const IMPORT_CARDS = gql`
  mutation ImportCardsMutation($listHandle: String!, $cards: [ImportCard!]!) {
    lists {
      importCards(listHandle: $listHandle, cards: $cards) {
        handle
      }
    }
  }`

const IMPORT_CARDS_OPTIONS = {
  refetchQueries: mutationResult => {
    console.log('refetchQueries')
    const listHandle = _.get('data.lists.importCards.handle', mutationResult)
    console.log(`IMPORT_CARDS_OPTIONS: ${listHandle}`)
    return [{ query: GET_LIST, variables: { listHandle }, fetchPolicy: 'no-cache' }]
  }
}

ImportCardsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.func,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,

  listHandle: PropTypes.string.isRequired
}

ImportCardsDialog.defaultProps = {
  title: 'Import Cards',
  cancelText: 'Cancel',
  confirmText: 'Done'
}

export default function ImportCardsDialog (props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [importCards] = useMutation(IMPORT_CARDS, IMPORT_CARDS_OPTIONS)

  const [importedCards, setImportedCards] = React.useState([])

  function handleClose () {
    props.setOpen(false)
  }

  async function handleConfirm (event) {
    event.preventDefault()
    await importCards({ variables: { listHandle: props.listHandle, cards: importedCards } })
    if (props.action) {
      props.action()
    }
    handleClose()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'sm'}
      fullWidth
      open={props.open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{props.title}</DialogTitle>
      <form
        autoComplete='off'
        noValidate
        onSubmit={handleConfirm}
      >
        <DialogContent>

          <UploadButton
            onFileSelected={setImportedCards}
          >
            Import
          </UploadButton>

          <div>{_.size(importedCards)} imported card(s)</div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            {props.cancelText}
          </Button>
          <Button onClick={handleConfirm} color='primary' type='submit'>
            {props.confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

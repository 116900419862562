import React from 'react'
import _ from 'lodash/fp'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import Snackbar from '@material-ui/core/Snackbar'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Check'

import Noop from 'components/Noop'
import { RedButton, GreenButton } from 'components/Buttons'

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
}))

SelectedCardsBar.propTypes = {
  selectedCards: PropTypes.arrayOf(PropTypes.number),
  setSelectedCards: PropTypes.func,
  upsertCards: PropTypes.func.isRequired,
  selectedAmount: PropTypes.number.isRequired
}

SelectedCardsBar.defaultProps = {
  selectedCards: [],
  selectedAmount: 0
}

export default function SelectedCardsBar ({
  selectedCards,
  setSelectedCards,
  upsertCards,
  selectedAmount
}) {
  const classes = useStyles()

  // value of input field
  const [quantity, setQuantity] = React.useState(0)

  // to avoid rendering that 0 is selected when component leaves
  const [selectedCount, setSelectedCount] = React.useState(_.size(selectedCards))

  React.useEffect(() => {
    if (!_.isEmpty(selectedCards)) {
      setSelectedCount(_.size(selectedCards))
    }
  }, [selectedCards, setSelectedCount])

  React.useEffect(() => {
    setQuantity(selectedAmount)
  }, [selectedAmount])

  const handleChangeQuantity = React.useCallback(e => {
    const value = parseInt(e.target.value)
    setQuantity((isNaN(value) || value < 0) ? '' : value)
  }, [setQuantity])

  const handleSaveQuantity = React.useCallback(() => {
    upsertCards(_.map(mtgoId => ({ mtgoId, quantity: parseInt(quantity) }), selectedCards))
    setSelectedCards([])
  }, [quantity, selectedCards, setSelectedCards, upsertCards])

  const handleDelete = React.useCallback(() => {
    upsertCards(_.map(mtgoId => ({ mtgoId, quantity: 0 }), selectedCards))
    setSelectedCards([])
  }, [selectedCards, upsertCards, setSelectedCards])

  const handleClose = React.useCallback(() => {
    setSelectedCards([])
  }, [setSelectedCards])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={!_.isEmpty(selectedCards)}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
    >
      <Paper style={{ padding: '1em', minWidth: '300px' }}>

        <Grid container wrap='nowrap' spacing={2}>
          <Grid container item xs={6} alignItems='center' justify='center'>
            <Typography style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{`${selectedCount} selected`}</Typography>
          </Grid>
          <Grid item xs>
            <Divider orientation='vertical' />
          </Grid>
          <Grid container item xs alignItems='center' justify='center'>

            <ButtonGroup variant='contained' color='primary' aria-label='split button'>
              <Noop>
                <Input
                  name='name'
                  onChange={handleChangeQuantity}
                  required
                  value={quantity}
                  variant='standard'
                  disableUnderline
                  startAdornment='#'
                  type='number'
                  inputProps={{ style: { textAlign: 'center' } }}
                  style={{ width: '70px', borderStyle: 'none', padding: '0 0 0 5px' }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSaveQuantity()
                    }
                  }}
                />
              </Noop>

              <GreenButton
                disabled={!quantity}
                color='primary'
                aria-owns={undefined}
                aria-haspopup='true'
                size='medium'
                onClick={handleSaveQuantity}
              >
                <SaveIcon />
              </GreenButton>
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <Divider orientation='vertical' />
          </Grid>
          <Grid container item xs alignItems='center' justify='center'>
            <RedButton
              className={clsx([classes.deleteButton])}
              onClick={handleDelete}
              variant='contained'
              size='medium'
            >
                Delete
            </RedButton>
          </Grid>

          <Grid item xs>
            <Divider orientation='vertical' />
          </Grid>
          <Grid container item xs alignItems='center' justify='flex-end'>
            <IconButton
              key='close'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
              className={classes.close}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

      </Paper>
    </Snackbar>
  )
}

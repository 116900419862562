import React from 'react'
import _ from 'lodash/fp'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import AccountDetails from './components/AccountDetails'
import AccountPassword from './components/AccountPassword'

import { VERIFY_SESSION } from 'common/queries'
import { useQuery, useMutation } from '@apollo/react-hooks'
import LoadingIndicator from 'components/LoadingIndicator'
import gql from 'graphql-tag'

const UPDATE_PROFILE = gql`
  mutation UpdateProfileMutation ($profile:AuthProfileInput!) {
    auth {
      updateProfile(profile:$profile)
    }
  }`

const UPDATE_PROFILE_OPTIONS = {
  refetchQueries: [{ query: VERIFY_SESSION }]
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const Account = () => {
  const classes = useStyles()

  const { data: sessionData, loading: sessionLoading } = useQuery(VERIFY_SESSION)
  const [updateProfileMutation] = useMutation(UPDATE_PROFILE, UPDATE_PROFILE_OPTIONS)

  const updateProfile = React.useCallback(profile => {
    updateProfileMutation({ variables: { profile } })
  }, [updateProfileMutation])

  const profile = _.flow(
    _.get('auth.verifySession.profile'),
    _.pick(['handle', 'name', 'email', 'primaryListHandle'])
  )(sessionData)
  const accountLoading = sessionLoading && _.isEmpty(profile)

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          {accountLoading ? <LoadingIndicator /> : <AccountDetails profile={profile} updateProfile={updateProfile} />}
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountPassword />
        </Grid>
      </Grid>
    </div>
  )
}

export default Account

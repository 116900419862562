import React from 'react'
import _ from 'lodash/fp'
import gql from 'graphql-tag'
import makeStyles from '@material-ui/styles/makeStyles'
import { useQuery, useMutation } from '@apollo/react-hooks'

import CardTable from './components/CardTable'
import ListToolbar from './components/ListToolbar'
import ImportCardsDialog from './components/ImportCardsDialog'
import AddCardsDialog from './components/AddCardsDialog'

import { GET_LIST } from 'common/queries'

const UPSERT_CARDS = gql`
  mutation UpsertCardsMutation($listHandle: String!, $cards: [ImportCard!]!) {
    lists {
      upsertCards(listHandle: $listHandle, cards: $cards) {
        listHandle
        cards {
          mtgoId
          quantity
        }
      }
    }
  }
`

const UPSERT_CARDS_OPTIONS = {
  update (cache, props) {
    const result = _.get('data.lists.upsertCards', props) // mtgoId, quantity
    const listHandle = _.get('listHandle', result)

    const deletedMtgoIds = _.flow(_.get('cards'), _.filter(c => c.quantity === 0), _.map('mtgoId'))(result)
    const updatedCards = _.flow(_.get('cards'), _.filter(c => c.quantity > 0), _.keyBy('mtgoId'))(result)

    const listCache = cache.readQuery({
      query: GET_LIST,
      variables: { listHandle }
    })

    const data = _.update(
      'lists.getList.cards',
      _.flow(
        _.reject(c => _.includes(c.mtgoId, deletedMtgoIds)),
        _.map(c => _.get(c.mtgoId, updatedCards)
          ? _.set('quantity', _.get(`${c.mtgoId}.quantity`, updatedCards), c)
          : c
        )
      ),
      listCache
    )

    cache.writeQuery({
      query: GET_LIST,
      variables: { listHandle },
      data
    })
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))

export default function List (props) {
  const classes = useStyles()

  const listHandle = _.get('match.params.listHandle', props)

  const [selectedCards, setSelectedCards] = React.useState([])
  const [importDialogOpen, setImportDialogOpen] = React.useState(false)
  const [addDialogMtgoId, setAddDialogMtgoId] = React.useState(undefined)

  const [upsertCardsMutation] = useMutation(UPSERT_CARDS, UPSERT_CARDS_OPTIONS)

  const getListQuery = useQuery(GET_LIST, { variables: { listHandle } })
  if (getListQuery.loading && _.isEmpty(getListQuery.data)) {
    return (
      <div className={classes.root}>
        Loading
      </div>
    )
  }
  const list = _.get('lists.getList', getListQuery.data)

  const upsertCards = cards => {
    upsertCardsMutation({ variables: {
      listHandle,
      cards
    } })
  }

  return (
    <div className={classes.root}>
      <ListToolbar
        handleImportCards={() => setImportDialogOpen(true)}
        setAddDialogMtgoId={setAddDialogMtgoId}
      />
      <div className={classes.content}>
        <CardTable
          cards={_.get('cards', list)}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          upsertCards={upsertCards}
        />
      </div>
      <ImportCardsDialog
        open={importDialogOpen}
        setOpen={setImportDialogOpen}
        listHandle={listHandle}
      />
      <AddCardsDialog
        mtgoId={addDialogMtgoId}
        setMtgoId={setAddDialogMtgoId}
        listHandle={listHandle}
      />
    </div>
  )
}

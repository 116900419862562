import React from 'react'
import _ from 'lodash/fp'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import useReactRouter from 'helpers/useReactRouter'

import SearchCard from 'components/SearchCard'
import { useQuery } from '@apollo/react-hooks'
import { GET_SETS } from 'common/queries'
import LoadingIndicator from 'components/LoadingIndicator'
import CardsList from 'components/CardsList'
import gql from 'graphql-tag'

import SetsList from './components/SetsList'

const GET_SET_CARDS = gql`
  query GetSetCards ($field: SearchNumberField!, $operator: OrderByOperator, $setCode: String!) {
    cards {
      findCards (
        orderBy: [{ field: $field, operator: $operator }],
        textFilters: [{ field: set, value: $setCode }],
        limit: 50,
        offset: 0
      ) {
        mtgoId
        name
        set
        tix
        delta
        deltaWeek
        deltaQuarter
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

export default function Cards (props) {
  const classes = useStyles()
  const { history, match } = useReactRouter()

  const { data: setsData, loading: setsLoading } = useQuery(GET_SETS)

  const sets = !setsLoading && _.get('cards.getSets', setsData)

  const loading = setsLoading && !setsData

  const setCode = _.get('params.setCode', match)

  const { data: cardsData, loading: cardsLoading } = useQuery(GET_SET_CARDS, { skip: !setCode, variables: { field: 'tix', operator: 'DESC', setCode } })

  const cards = _.get('cards.findCards', cardsData)

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={7}
          xs={12}
        >
          <SearchCard onSelect={({ mtgoId, set }) => history.push(`/cards/${set}/${mtgoId}`)} />

          {cardsLoading ? <LoadingIndicator /> : <CardsList cards={cards} title={_.toUpper(setCode)} />}
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          {loading ? <LoadingIndicator /> : <SetsList sets={sets} selectedSet={setCode} />}
        </Grid>
      </Grid>
    </div>
  )
}

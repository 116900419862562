import React, { useState, useEffect } from 'react'
import _ from 'lodash/fp'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import localStore from 'localforage'
import gql from 'graphql-tag'
import { makeDigest } from '@magic/brain/src/util'
import { makeStyles } from '@material-ui/styles'
import validate from 'validate.js'
import { withSnackbar } from 'notistack'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import schema from 'common/schema'
import { SESSION_KEY } from 'config/keys'

const REGISTER_USER = gql`
  mutation RegisterUser($userInput: UserInput) {
    auth {
      registerUser (userInput: $userInput) {
        session
        profile {
          handle
          name
          email
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}))

const SignUp = props => {
  const { history } = props

  const classes = useStyles()

  const [registerUser, registerUserMetadata] = useMutation(REGISTER_USER)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: 'a',
      handle: 'a',
      name: 'foo',
      email: 'a@b.c'
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleSignUp = async event => {
    event.preventDefault()
    try {
      const userInput = {
        name: formState.values.name,
        handle: formState.values.handle,
        email: formState.values.email,
        digest: makeDigest({ password: formState.values.password, handle: formState.values.handle })
      }
      const result = await registerUser({ variables: { userInput } })
      const session = _.get('data.auth.registerUser.session', result)
      await localStore.setItem(SESSION_KEY, session)
      history.push('/')
    } catch (error) {
      _.flow(
        _.get('graphQLErrors'),
        _.each(({ message }) => {
          props.enqueueSnackbar(message, { variant: 'error' })
        })
      )(error)
    }
  }

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field])

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant='h1'
              >
                A chorus of life heralds the arrival of Mirrodin's final sun.
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignUp}
              >
                <Typography
                  className={classes.title}
                  variant='h2'
                >
                  Create new account
                </Typography>
                <Typography
                  color='textSecondary'
                  gutterBottom
                >
                  Use your email to create new account
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('name')}
                  fullWidth
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  label='Name'
                  name='name'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.name || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('handle')}
                  fullWidth
                  helperText={
                    hasError('handle') ? formState.errors.handle[0] : null
                  }
                  label='Username'
                  name='handle'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.handle || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label='Email address'
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label='Password'
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <Button
                  className={classes.signUpButton}
                  color='primary'
                  disabled={!formState.isValid || registerUserMetadata.loading}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Sign up now
                </Button>
                <Typography
                  color='textSecondary'
                  variant='body1'
                >
                  Have an account?{' '}
                  <Link
                    component={RouterLink}
                    to='/sign-in'
                    variant='h6'
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignUp.propTypes = {
  history: PropTypes.object
}

export default withSnackbar(withRouter(SignUp))

import Button from '@material-ui/core/Button'

import withStyles from '@material-ui/styles/withStyles'
import { green as greenColor, red as redColor, grey as greyColor } from '@material-ui/core/colors'

export const RedButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(redColor[500]),
    backgroundColor: redColor[500],
    '&:hover': {
      backgroundColor: redColor[700]
    }
  }
}))(Button)

export const GreenButton = withStyles(theme => ({
  root: {
    color: greyColor[100],
    backgroundColor: greenColor[500],
    '&:hover': {
      backgroundColor: greenColor[700]
    }
  }
}))(Button)

export default {
  RedButton,
  GreenButton
}

import { colors } from '@material-ui/core'

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=775447&secondary.color=E91E63&secondary.text.color=FCE4EC

const white = '#FFFFFF'
const black = '#000000'

export default {
  black,
  white,
  primary: {
    light: 'rgba(94, 146, 243, 1)',
    main: 'rgba(21, 101, 192, 1)',
    contrastText: white
  },
  secondary: {
    light: 'rgba(167, 129, 114, 1)',
    main: 'rgba(119, 84, 71, 1)',
    dark: 'rgba(74, 43, 32, 1)',
    contrastText: white
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff'
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)'
  },
  background: {
    primary: '#fafafa',
    secondary: '#ff00ff',
    paper: white
  },
  icon: colors.grey[800],
  divider: colors.grey[200]
}

// {"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(167, 129, 114, 1)","main":"rgba(119, 84, 71, 1)","dark":"rgba(74, 43, 32, 1)","contrastText":"#fff"},"secondary":{"light":"rgba(94, 146, 243, 1)","main":"rgba(21, 101, 192, 1)","dark":"rgba(0, 60, 143, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}}

import React from 'react'
import _ from 'lodash/fp'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core'

import LinkRouter from 'components/LinkRouter'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

ListTable.propTypes = {
  className: PropTypes.string,
  lists: PropTypes.array.isRequired,
  selectedLists: PropTypes.array,
  setSelectedLists: PropTypes.func
}

ListTable.defaultProps = {
  lists: [],
  selectedLists: []
}

export default function ListTable ({
  className,
  lists,
  selectedLists,
  setSelectedLists
}) {
  const classes = useStyles()

  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [page, setPage] = React.useState(0)

  React.useEffect(() => {
    // deselect all when adding or deleting lists
    setSelectedLists([])
  }, [lists, setSelectedLists])

  const pageLists = lists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const allChecked = selectedLists.length > 0 && _.reduce((m, c) => m && _.includes(c, selectedLists), true, _.map('handle', pageLists))

  const handleSelectAll = _event => {
    setSelectedLists(allChecked ? [] : _.map('handle', pageLists))
  }

  const handleSelectOne = (_event, handle) => {
    setSelectedLists(lists => _.includes(handle, lists) ? _.without([handle], lists) : _.concat(lists, [handle]))
  }

  const handlePageChange = (_event, page) => {
    setPage(page)
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={allChecked}
                    color='primary'
                    indeterminate={selectedLists.length > 0 && !allChecked}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Unique Cards</TableCell>
                <TableCell>Total Cards</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageLists.map(list => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={list.handle}
                  selected={selectedLists.indexOf(list.handle) !== -1}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectedLists.indexOf(list.handle) !== -1}
                      color='primary'
                      onChange={event => handleSelectOne(event, list.handle)}
                      value='true'
                    />
                  </TableCell>
                  <TableCell>
                    <div className={classes.nameContainer}>
                      <LinkRouter to={`lists/${list.handle}`}>
                        <Typography variant='body1'>
                          {list.name}
                        </Typography>
                      </LinkRouter>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.nameContainer}>
                      <Typography variant='body1'>{list.uniqueCards}</Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.nameContainer}>
                      <Typography variant='body1'>{list.totalCards}</Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    {moment.unix(list.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component='div'
          count={lists.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
        />
      </CardActions>
    </Card>
  )
}

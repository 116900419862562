import React from 'react'
import _ from 'lodash/fp'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { GET_LISTS, VERIFY_SESSION } from 'common/queries'

const UPDATE_PROFILE = gql`
mutation AuthUpdateProfile($profile: AuthProfileInput!) {
  auth {
    updateProfile(profile: $profile)
  }
}
`

const UPDATE_PROFILE_OPTIONS = {
  refetchQueries: [{ query: VERIFY_SESSION, fetchPolicy: 'no-cache' }]
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

PrimaryListHandleDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,

  action: PropTypes.func,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,

  listHandle: PropTypes.string
}

PrimaryListHandleDialog.defaultProps = {
  title: 'Set primary list (main collection)',
  cancelText: 'Cancel',
  confirmText: 'Save'
}

export default function PrimaryListHandleDialog (props) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { open, setOpen, title } = props

  const [selectedHandle, setSelectedHandle] = React.useState('')

  const { data: lists } = useQuery(GET_LISTS)
  const { data: profileData } = useQuery(VERIFY_SESSION)

  const [updateProfile] = useMutation(UPDATE_PROFILE, UPDATE_PROFILE_OPTIONS)

  const profile = _.get('auth.verifySession.profile', profileData)
  const primaryListHandle = _.getOr('', 'primaryListHandle', profile)

  React.useEffect(() => {
    setSelectedHandle(primaryListHandle)
  }, [primaryListHandle])

  function handleClose () {
    setOpen(false)
  }

  async function handleConfirm (event) {
    event.preventDefault()
    updateProfile({ variables: { profile: { primaryListHandle: selectedHandle } } })
    handleClose()
  }

  function handleChange (e) {
    setSelectedHandle(e.target.value)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'sm'}
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{title}</DialogTitle>
      <form
        autoComplete='off'
        noValidate
        onSubmit={handleConfirm}
      >
        <DialogContent style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor='primary-list'>Primary List</InputLabel>
            <Select
              value={selectedHandle}
              onChange={handleChange}
              inputProps={{ id: 'primary-list' }}
            >
              <MenuItem value={''}><em>None</em></MenuItem>
              {_.map(l => (
                <MenuItem value={l.handle} key={l.handle}>{l.name}</MenuItem>
              ), _.get('lists.getLists', lists))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            {props.cancelText}
          </Button>
          <Button onClick={handleConfirm} color='primary' type='submit'>
            {props.confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

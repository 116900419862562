import React from 'react'
import _ from 'lodash/fp'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const regex = /.*CatID="(\d+)".*Quantity="(\d+)".*Sideboard="(.+)".*Name="(.+)".*/i

const extractCards = _.flow(
  _.split('\n'),
  _.filter(_.includes('Cards')),
  _.map(line => {
    const match = regex.exec(line)
    return {
      mtgoId: parseInt(_.get(1, match)),
      quantity: parseInt(_.get(2, match))
      // side: _.get(3, match) === 'true' ? true : undefined,
      // name: _.get(4, match)
    }
  })
)

const handleUpload = (onFileSelected) => (fileEvent) => {
  const reader = new window.FileReader()
  reader.onloadend = (loadEvent) => onFileSelected(extractCards(loadEvent.target.result))
  reader.readAsText(_.first(fileEvent.target.files))
}

UploadButton.propTypes = {
  className: PropTypes.string,
  onFileSelected: PropTypes.func.isRequired,
  children: PropTypes.node,
  buttonProps: PropTypes.object
}

UploadButton.defaultProps = {
  className: '',
  children: 'Upload',
  buttonProps: {}
}

export default function UploadButton (props) {
  return (
    <div>
      <input
        style={{ display: 'none' }}
        id='upload'
        type='file'
        onChange={handleUpload(props.onFileSelected)}
      />
      <label htmlFor='upload'>
        <Button className={props.className} component='span' {...props.buttonProps}>
          {props.children}
        </Button>
      </label>
    </div>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    background: 'url(/images/not-found.png) no-repeat center center',
    backgroundSize: 'cover',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const NotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Typography variant='h1' style={{
        textShadow: `
          5px 5px 0px rgb(5, 208, 189),
          10px 10px 0px hotpink,
          15px 15px 0px  rgb(151, 0, 189),
          20px 20px 0px  #DDD`,
        fontSize: '100px',
        color: 'white',
        padding: '1em'
      }}>
        404
      </Typography>
      <Typography variant='h1' style={{
        textShadow: `
          -5px 5px 0px rgb(5, 208, 189),
          -10px 10px 0px hotpink,
          -15px 15px 0px  rgb(151, 0, 189),
          -20px 20px 0px  #DDD`,
        fontVariant: 'unicase',
        fontSize: '75px',
        color: 'white'
      }}>
        Get Dazed
      </Typography>
    </div>
  )
}

export default NotFound

import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {}
}))

AccountDetails.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    primaryListHandle: PropTypes.string
  }).isRequired,
  updateProfile: PropTypes.func.isRequired
}

export default function AccountDetails ({ className, profile, updateProfile }) {
  const classes = useStyles()

  const [values, setValues] = useState(profile)

  React.useEffect(() => {
    setValues(profile)
  }, [profile])

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = React.useCallback(e => {
    e.preventDefault()
    updateProfile(values)
  }, [values, updateProfile])

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit}
      >
        <CardHeader
          subheader='Change your identity with a few clicks'
          title='Profile'
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label='Name'
            name='name'
            onChange={handleChange}
            required
            value={values.name}
            variant='outlined'
          />

          <TextField
            style={{ marginTop: '1rem' }}
            fullWidth
            helperText='Changing this will break any links you may have shared'
            label='Handle'
            name='handle'
            onChange={handleChange}
            required
            value={values.handle}
            variant='outlined'
          />

          <TextField
            style={{ marginTop: '1rem' }}
            fullWidth
            label='Email Address'
            name='email'
            onChange={handleChange}
            required
            value={values.email}
            variant='outlined'
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color='primary'
            variant='contained'
            type='submit'
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/styles'
import { ApolloProvider } from '@apollo/react-hooks'
import { SnackbarProvider } from 'notistack'

import apolloClient from './config/apollo'
import theme from './theme'
import './assets/scss/index.scss'

import Routes from './Routes'

const browserHistory = createBrowserHistory()

export default class App extends Component {
  render () {
    return (
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
    )
  }
}

import React from 'react'
import _ from 'lodash/fp'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import LinkRouter from 'components/LinkRouter'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  listRoot: {
    width: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  setCodeContainer: {
    minWidth: '48px',
    display: 'inline-flex',
    flexShrink: 0
  },
  tixContainer: {
    minWidth: '48px',
    textAlign: 'right',
    flexShrink: 0
  },
  cardNameText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis'
  },
  setText: {
    textAlign: 'center',
    border: '1px solid #DDD',
    color: '#333',
    padding: '0 5px',
    fontVariant: 'all-small-caps'
  },
  tixText: {
    textAlign: 'center',
    background: '#DDD',
    color: '#333',
    padding: '0 3px',
    fontVariant: 'all-small-caps'
  },
  deltaText: {
    textAlign: 'center',
    color: '#f0f0f0',
    padding: '0 3px',
    fontVariant: 'all-small-caps'
  }
}))

CardsList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  cards: PropTypes.array,
  deltaKey: PropTypes.string
}

CardsList.defaultProps = {
  deltaKey: 'delta'
}

export default function CardsList ({
  className,
  title,
  cards,
  deltaKey
}) {
  const classes = useStyles()

  if (_.isEmpty(cards)) {
    return false
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Typography
          className={classes.title}
          color='textSecondary'
          gutterBottom
          variant='body2'
        >
          {title}
        </Typography>

        <div className={classes.difference}>
          <List dense className={classes.listRoot}>
            {_.map(c => (
              <ListItem key={c.mtgoId} component={LinkRouter} to={`/cards/${c.set}/${c.mtgoId}`} style={{padding: 0}}>
                <div className={classes.setCodeContainer}>
                  <span className={classes.setText}>
                    {c.set}
                  </span>
                </div>
                <ListItemText primary={c.name} className={classes.cardNameText} />

                <div className={classes.tixContainer}>
                  <span className={classes.tixText}>
                    {c.tix}
                  </span>
                  <span className={classes.deltaText} style={{
                    background: c[deltaKey] < 0 ? '#f44336' : '#4caf50'
                  }}>
                    {c[deltaKey]}
                  </span>
                </div>
              </ListItem>
            ), cards)}
          </List>
        </div>
      </CardContent>
    </Card>
  )
}

import React from 'react'
import _ from 'lodash/fp'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import CardCard from './components/CardCard'

const GET_CARD = gql`
query GetCardsQuery($mtgoId: Int!) {
  cards {
    getCard(mtgoId:$mtgoId){
      name
      tix
      mtgoId
      set
      tixHistory
    }
  }
}
`

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const mtgoIdFromParams = _.flow(
  _.get('match.params.mtgoId'),
  _.parseInt(10)
)

export default function Card (props) {
  const classes = useStyles()
  const mtgoId = mtgoIdFromParams(props)
  const { loading, data } = useQuery(GET_CARD, { variables: { mtgoId } })
  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress color='primary' />
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={7}
          xs={12}
        >
          <CardCard card={_.get('cards.getCard', data)} />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          <div>Insert Chart Here</div>
        </Grid>
      </Grid>
    </div>
  )
}

import React, { useState } from 'react'
import _ from 'lodash/fp'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Route } from 'react-router'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { useMediaQuery } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'

import HomeIcon from '@material-ui/icons/Home'

import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'
import Footer from './components/Footer'

import { VERIFY_SESSION } from 'common/queries'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  icon: {
    margin: theme.spacing(0.5),
    width: 16,
    height: 16
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  },
  link: {
    display: 'flex'
  },
  breadcrumbContainer: {
    padding: theme.spacing(2)
  }
}))

const LinkRouter = props => <Link {...props} component={RouterLink} />

const breadcrumbNameMap = {
  '/home': 'Dashboard',
  '/cards': 'Cards',
  '/lists': 'Lists',
  '/print': 'Print',
  '/account': 'Account'
}

const Main = props => {
  const { children } = props

  const { data: profileData, loading: profileLoading, error: profileError } = useQuery(VERIFY_SESSION)
  const profile = _.get('auth.verifySession.profile', profileData)

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  if (!profileError && !profile && profileLoading) {
    return false
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar
        profile={profile}
        onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        profile={profile}
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        <Route>
          {({ location }) => {
            const pathnames = location.pathname.split('/').filter(x => x)

            return (
              <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumbContainer}>
                <LinkRouter color='inherit' to='/' style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <HomeIcon className={classes.icon} />
                  Home
                </LinkRouter>
                {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`

                  return last ? (
                    <Typography color='textPrimary' key={to}>
                      {breadcrumbNameMap[to] || pathnames[index]}
                    </Typography>
                  ) : (
                    <LinkRouter color='inherit' to={to} key={to}>
                      {breadcrumbNameMap[to] || value}
                    </LinkRouter>
                  )
                })}
              </Breadcrumbs>
            )
          }}
        </Route>
        {children}
        <Footer />
      </main>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node
}

export default Main

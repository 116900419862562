import React from 'react'
import _ from 'lodash/fp'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Input from '@material-ui/core/Input'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SaveIcon from '@material-ui/icons/Check'
import { useTheme } from '@material-ui/core/styles'

import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Noop from 'components/Noop'
import { GreenButton } from 'components/Buttons'
import { GET_LIST } from 'common/queries'

const ADD_LIST_CARD = gql`
  mutation AddListCardMutation($listCard: ListCardInput!) {
    lists {
      upsertCard(listCard: $listCard) {
        name
        set
        mtgoId
        createdAt
        updatedAt
        tix
        delta
        deltaWeek
        deltaMonth
        deltaQuarter
        quantity
        listHandle
      }
    }
  }
`

const ADD_LIST_CARD_OPTIONS = {
  update (cache, props) {
    const card = _.get('data.lists.upsertCard', props)
    const listHandle = _.get('listHandle', card)
    const getListCache = cache.readQuery({
      query: GET_LIST,
      variables: { listHandle }
    })

    const data = _.update(
      'lists.getList.cards',
      card.quantity === 0
        ? _.reject(_.flow(_.get('mtgoId'), _.eq(card.mtgoId)))
        : card.createdAt === card.updatedAt
          ? _.concat([card])
          : _.map(c => c.mtgoId === card.mtgoId ? _.set('quantity', card.quantity, c) : c),
      getListCache
    )

    cache.writeQuery({
      query: GET_LIST,
      variables: { listHandle },
      data
    })
  }
}

AddCardsDialog.propTypes = {
  mtgoId: PropTypes.number,
  setMtgoId: PropTypes.func,

  action: PropTypes.func,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,

  listHandle: PropTypes.string.isRequired
}

AddCardsDialog.defaultProps = {
  title: 'Number of cards to add',
  cancelText: 'Cancel',
  confirmText: 'Add'
}

export default function AddCardsDialog (props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { mtgoId } = props

  const [quantity, setQuantity] = React.useState(1)

  React.useEffect(() => {
    // reset when a new mtgoId is selected
    setQuantity(1)
  }, [mtgoId])

  const [addListCard] = useMutation(ADD_LIST_CARD, ADD_LIST_CARD_OPTIONS)

  function handleClose () {
    props.setMtgoId(undefined)
  }

  async function handleConfirm (event) {
    event.preventDefault()
    await addListCard({ variables: { listCard: {
      listHandle: props.listHandle,
      quantity,
      mtgoId: props.mtgoId
    } } })
    if (props.action) {
      props.action()
    }
    handleClose()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'sm'}
      open={Boolean(props.mtgoId)}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{props.title}</DialogTitle>
      <form
        autoComplete='off'
        noValidate
        onSubmit={handleConfirm}
      >
        <DialogContent style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

          <ButtonGroup variant='contained' color='primary' aria-label='split button'>
            <Noop>
              <Input
                autoFocus
                name='name'
                onChange={e => {
                  const value = parseInt(e.target.value)
                  setQuantity((isNaN(value) || value < 0) ? '' : value)
                }}
                required
                value={quantity}
                variant='standard'
                disableUnderline
                startAdornment='#'
                type='number'
                inputProps={{ style: { textAlign: 'center' } }}
                style={{ width: '70px', borderStyle: 'none', padding: '0 0 0 5px' }}
              />
            </Noop>
            <GreenButton
              disabled={!quantity}
              color='primary'
              aria-owns={undefined}
              aria-haspopup='true'
              size='medium'
              type='submit'
            >
              <SaveIcon />
            </GreenButton>
          </ButtonGroup>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            {props.cancelText}
          </Button>
          <Button onClick={handleConfirm} color='primary' type='submit'>
            {props.confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

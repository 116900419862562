import React from 'react'
import _ from 'lodash/fp'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import validate from 'validate.js'

import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import schema from 'common/schema'
import { GET_LISTS } from 'common/queries'

const ADD_LIST = gql`
  mutation AddListMutation($list: AddListInput!) {
    lists {
      addList(list: $list) {
        handle
        userHandle
        name
        createdAt
        cards {
          mtgoId
          quantity
        }
      }
    }
  }`

const ADD_LIST_OPTIONS = {
  refetchQueries: [{ query: GET_LISTS }]
}

ListsAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.func,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string
}

ListsAddDialog.defaultProps = {
  title: 'Add List',
  cancelText: 'Cancel',
  confirmText: 'Done'
}

export default function ListsAddDialog (props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [addList] = useMutation(ADD_LIST, ADD_LIST_OPTIONS)

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {
      handle: '',
      name: ''
    },
    touched: {},
    errors: {}
  })

  const hasError = field => !!(formState.touched[field] && formState.errors[field])

  React.useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  React.useEffect(() => {
    const handle = _.flow(
      _.replace(/ /g, '-'),
      _.toLower,
      _.replace(/[^a-z0-9\\-]/g, '')
    )(formState.values.name)
    setFormState(_.set('values.handle', handle))
  }, [formState.values.name])

  function handleClose () {
    props.setOpen(false)
  }

  async function handleConfirm (event) {
    event.preventDefault()
    const list = {
      name: formState.values.name,
      handle: formState.values.handle
    }
    await addList({ variables: { list } })
    if (props.action) {
      props.action()
    }
    handleClose()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'sm'}
      fullWidth
      open={props.open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{props.title}</DialogTitle>
      <form
        autoComplete='off'
        noValidate
        onSubmit={handleConfirm}
      >
        <DialogContent>

          <TextField
            autoFocus
            error={hasError('name')}
            fullWidth
            helperText={
              hasError('name') ? formState.errors.name[0] : null
            }
            label='Name'
            name='name'
            onChange={handleChange}
            required
            value={formState.values.name}
            variant='outlined'
          />

          <TextField
            style={{ marginTop: '1rem' }}
            error={hasError('handle')}
            fullWidth
            helperText={
              hasError('handle') ? formState.errors.handle[0] : null
            }
            label='Handle'
            name='handle'
            onChange={handleChange}
            required
            value={formState.values.handle}
            variant='outlined'
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            {props.cancelText}
          </Button>
          <Button onClick={handleConfirm} color='primary' type='submit'>
            {props.confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export default function Summary ({ list }) {
  if (!list) {
    return (
      <Typography>No collection has been set.</Typography>
    )
  }
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={4}>{list.totalCards} cards</Grid>
        <Grid item md={4}>{list.uniqueCards} unique cards</Grid>
        <Grid item md={4}>{list.totalTix} tix</Grid>
      </Grid>
    </React.Fragment>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Paper,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3)
  },
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2)
  }
}))

export default function CardCard ({ card }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant='h3' gutterBottom>
        {card.name}
      </Typography>
      <Paper className={classes.paper}>
        <Grid container wrap='nowrap' spacing={2}>
          <Grid item>
            <Typography>{'Name'}</Typography>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography>{card.name}</Typography>
          </Grid>

          <Grid item>
            <Typography>{'Set'}</Typography>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography>{card.set}</Typography>
          </Grid>

          <Grid item>
            <Typography>{'Tix'}</Typography>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography>{card.tix}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

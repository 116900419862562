import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'

// import UploadButton from 'components/UploadButton'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  marginRightButton: {
    marginRight: theme.spacing(1)
  }
}))

ListToolbar.propTypes = {
  className: PropTypes.string,
  handleAddList: PropTypes.func.isRequired,
  handleDeleteLists: PropTypes.func.isRequired,
  deleteListsDisabled: PropTypes.bool.isRequired
}

export default function ListToolbar (props) {
  const classes = useStyles()

  // <UploadButton
  // onFileSelected={text => { console.log(text); window.___cards = text }}
  // className={classes.importButton}>Import</UploadButton>

  return (
    <div
      className={clsx(classes.root, props.className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          disabled={props.deleteListsDisabled}
          variant='text'
          className={classes.marginRightButton}
          onClick={props.handleDeleteLists}
        >
          Delete
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={props.handleAddList}
        >
          Add list
        </Button>
      </div>
    </div>
  )
}

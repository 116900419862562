import React from 'react'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

export function LinkRouter (props) {
  return <Link {...props} component={RouterLink} />
}

const CustomLinkRouter = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <LinkRouter {...props} />
  </div>
))

export default CustomLinkRouter

import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {}
}))

AccountDetails.propTypes = {
  className: PropTypes.string
}

export default function AccountDetails (props) {
  const { className, ...rest } = props

  const classes = useStyles()

  const [values, setValues] = useState({
    password: '',
    confirmPassword: ''
  })

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete='off'
        noValidate
      >
        <CardHeader
          subheader='Change password - Not implemented'
          title='Password'
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            type='password'
            label='Password'
            name='password'
            onChange={handleChange}
            required
            value={values.password}
            variant='outlined'
            disabled
          />
          <TextField
            style={{ marginTop: '1rem' }}
            fullWidth
            type='password'
            label='Confirm password'
            name='confirmPassword'
            onChange={handleChange}
            required
            value={values.confirmPassword}
            variant='outlined'
            disabled
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color='primary'
            variant='contained'
            disabled
          >
            Update password
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Drawer } from '@material-ui/core'
import CardsIcon from '@material-ui/icons/Cake'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import AccountBoxIcon from '@material-ui/icons/AccountBox'

import Profile from './components/Profile'
import SidebarNav from './components/SidebarNav'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}))

const pages = [
  {
    title: 'Dashboard',
    href: '/home',
    icon: <DashboardIcon />
  },
  {
    title: 'Cards',
    href: '/cards',
    icon: <CardsIcon />
  },
  {
    title: 'Lists',
    href: '/lists',
    icon: <ShoppingBasketIcon />
  },
  {
    title: 'Print',
    href: '/print',
    icon: <ShoppingBasketIcon />
  },
  {
    title: 'Account',
    href: '/account',
    icon: <AccountBoxIcon />
  }
]

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    handle: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string
  })
}

export default function Sidebar (props) {
  const { open, variant, onClose, className, profile } = props

  const classes = useStyles()

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={clsx(classes.root, className)}
      >
        <Profile profile={profile} />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  )
}

import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import InputIcon from '@material-ui/icons/Input'

import { useMutation, useApolloClient } from '@apollo/react-hooks'
import localStore from 'localforage'
import gql from 'graphql-tag'

import ConfirmDialog from 'components/ConfirmDialog'

import useReactRouter from 'helpers/useReactRouter'

import { SESSION_KEY } from 'config/keys'

const END_SESSION = gql`
  mutation EndSession {
    auth {
      endSession
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1em'
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}))

// @see logopony.com for logo stuff

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  profile: PropTypes.shape({
    handle: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    __typename: PropTypes.string
  })
}

export default function Topbar (props) {
  const classes = useStyles()

  const [notifications] = React.useState([])
  const [open, setOpen] = React.useState(false)

  const { history } = useReactRouter()

  const [endSession] = useMutation(END_SESSION)
  const apolloClient = useApolloClient()

  function handleSignOut () {
    setOpen(true)
  }

  async function handleSignOutConfirm () {
    await endSession()
    await localStore.removeItem(SESSION_KEY)
    await apolloClient.clearStore()
    history.push('/sign-in')
  }

  return (
    <AppBar
      position='fixed'
      className={clsx(classes.root, props.className)}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            color='inherit'
            onClick={props.onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to='/home'>
          <img
            alt='Logo'
            src='/images/logos/logo-magic.png'
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            disabled={!(props.profile)}
            color='inherit'
          >
            <Badge
              badgeContent={notifications.length}
              color='error'
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            disabled={!(props.profile)}
            className={classes.signOutButton}
            color='inherit'
            onClick={handleSignOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <ConfirmDialog open={open} setOpen={setOpen} action={handleSignOutConfirm} />
    </AppBar>
  )
}

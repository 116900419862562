import React, { useState, useEffect } from 'react'
import _ from 'lodash/fp'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useMutation } from '@apollo/react-hooks'
import localStore from 'localforage'
import gql from 'graphql-tag'
import { makeDigest } from '@magic/brain/src/util'
import validate from 'validate.js'
import { withSnackbar } from 'notistack'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { SESSION_KEY } from 'config/keys'

const AUTHENTICATE_USER = gql`
  mutation StartSession($authInput: AuthInput) {
    auth {
      startSession (authInput: $authInput) {
        session
        profile {
          handle
          name
          email
        }
      }
    }
  }
`

const schema = {
  handle: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: '[a-z0-9\\-]+',
      flags: 'g',
      message: 'can only contain a-z and 0-9'
    },
    length: {
      maximum: 32
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  quoteAuthor: {
    marginTop: theme.spacing(1),
    textAlign: 'right'
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}))

const SignIn = props => {
  const { history } = props

  const classes = useStyles()

  const [authenticateUser, authenticateUserMetadata] = useMutation(AUTHENTICATE_USER)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      handle: 'b',
      password: 'a'
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleBack = () => {
    history.push('/')
  }

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleSignIn = async event => {
    event.preventDefault()
    try {
      const authInput = {
        handle: formState.values.handle,
        digest: makeDigest({ password: formState.values.password, handle: formState.values.handle })
      }
      const result = await authenticateUser({ variables: { authInput } })
      const session = _.get('data.auth.startSession.session', result)
      await localStore.setItem(SESSION_KEY, session)
      history.push('/')
    } catch (error) {
      _.flow(
        _.get('graphQLErrors'),
        _.each(({ message }) => {
          props.enqueueSnackbar(message, { variant: 'error' })
        })
      )(error)
    }
  }

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field])

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant='h1'
              >
                Its diet consists of fruits, plants, small woodland animals, large woodland animals, woodlands, fruit groves, fruit farmers, and small cities.
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant='body1'
                >
                  Enormous Baloth
                </Typography>
                <Typography
                  className={classes.bio}
                  variant='body2'
                >
                  By Mark Tedin
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant='h2'
                >
                  Sign in
                </Typography>
                <Typography
                  color='textSecondary'
                  gutterBottom
                >
                  "You know I accept only one currency here, and yet you have sought me out. Why now do you hesitate?"
                </Typography>
                <Typography
                  className={classes.quoteAuthor}
                  color='textSecondary'
                  variant='body1'
                >
                  —Xathrid demon
                </Typography>

                <TextField
                  className={classes.textField}
                  error={hasError('handle')}
                  fullWidth
                  helperText={
                    hasError('handle') ? formState.errors.handle[0] : null
                  }
                  label='Username'
                  name='handle'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.handle || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label='Password'
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <Button
                  className={classes.signInButton}
                  color='primary'
                  disabled={!formState.isValid || authenticateUserMetadata.loading}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Sign in now
                </Button>
                <Typography
                  color='textSecondary'
                  variant='body1'
                >
                  Don't have an account?{' '}
                  <Link
                    component={RouterLink}
                    to='/sign-up'
                    variant='h6'
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignIn.propTypes = {
  history: PropTypes.object
}

export default withSnackbar(withRouter(SignIn))

import React from 'react'
import _ from 'lodash/fp'
import { makeStyles } from '@material-ui/styles'

import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import ConfirmDialog from 'components/ConfirmDialog'

import ListsTable from './components/ListsTable'
import ListsToolbar from './components/ListsToolbar'
import ListsAddDialog from './components/ListsAddDialog'

import { GET_LISTS } from 'common/queries'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))

const DELETE_LISTS = gql`
  mutation DeleteListsMutation($listHandles: [String!]!) {
    lists {
      deleteLists(handles: $listHandles)
    }
  }
`

const DELETE_LISTS_OPTIONS = {
  refetchQueries: [{ query: GET_LISTS }]
}

export default function Lists () {
  const classes = useStyles()

  const [addDialogOpen, setAddDialogOpen] = React.useState(false)
  const [deleteListsDialogOpen, setDeleteListsDialogOpen] = React.useState(false)
  const [selectedLists, setSelectedLists] = React.useState([])

  const { data } = useQuery(GET_LISTS)
  const [deleteListsMutation] = useMutation(DELETE_LISTS, DELETE_LISTS_OPTIONS)

  const deleteLists = () => {
    deleteListsMutation({ variables: { listHandles: selectedLists } })
  }

  return (
    <div className={classes.root}>
      <ListsToolbar
        handleAddList={() => setAddDialogOpen(true)}
        handleDeleteLists={() => setDeleteListsDialogOpen(true)}
        deleteListsDisabled={_.isEmpty(selectedLists)}
      />
      <div className={classes.content}>
        <ListsTable
          lists={_.get('lists.getLists', data)}
          selectedLists={selectedLists}
          setSelectedLists={setSelectedLists}
        />
      </div>
      <ListsAddDialog
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
      />
      <ConfirmDialog
        open={deleteListsDialogOpen}
        setOpen={setDeleteListsDialogOpen}
        action={deleteLists}
      />
    </div>
  )
}

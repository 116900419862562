import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import RouteWithLayout from './components/RouteWithLayout'
import MainLayout from './layouts/Main'
import MinimalLayout from './layouts/Minimal'

import CardView from './views/Cards/Card'
import CardsView from './views/Cards/Cards'

import DashboardView from './views/Dashboard'
import ListView from './views/Lists/List'
import ListsView from './views/Lists/Lists'
import PrintView from './views/Print/Print'
import AccountView from './views/Account'

import SignUpView from './views/SignUp'
import SignInView from './views/SignIn'
import NotFoundView from './views/NotFound'

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from='/'
        to='/home'
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path='/home'
      />
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path='/cards'
      />
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path='/cards/:setCode'
      />
      <RouteWithLayout
        component={CardView}
        exact
        layout={MainLayout}
        path='/cards/:setCode/:mtgoId'
      />
      <RouteWithLayout
        component={ListsView}
        exact
        layout={MainLayout}
        path='/lists'
      />
      <RouteWithLayout
        component={ListView}
        exact
        layout={MainLayout}
        path='/lists/:listHandle'
      />
      <RouteWithLayout
        component={PrintView}
        exact
        layout={MainLayout}
        path='/print'
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path='/account'
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path='/sign-up'
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path='/sign-in'
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path='/not-found'
      />
      <Redirect to='/not-found' />
    </Switch>
  )
}

export default Routes
